<template>
    <div id="root">
        
        <topNavBar :title="activeTitle" :leftArrow="leftArrow"></topNavBar>
        <div class="main-box">
            <transition name='fade' mode="out-in">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive" />
                </keep-alive>
            </transition> 
            <transition name='fade' mode="out-in">
                <router-view v-if="!$route.meta.keepAlive" />
            </transition>
        </div>
        
    </div>
</template>

<script>
import topNavBar from "@/components/topNavBar.vue";
export default {
    name: "App",
    data() {
        return {
            leftArrow: false,
            activeTitle: this.$route.meta.title,
        };
    },
    components: {
        topNavBar,
    },
    computed: {
        key() {
            return this.$route.name !== undefined
                ? this.$route.name + +new Date()
                : this.$route + +new Date();
        },
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(val) {
                this.activeTitle = val.meta.title;
                let noArrow = ["首页", "地图找房", "营信地产", "资讯", "顾问"];
                if (noArrow.includes(this.activeTitle)) {
                    this.leftArrow = false;
                } else {
                    this.leftArrow = true;
                }
                //console.log(this.$route.meta.title)
            },
        },
    },
    created() {},
    methods: {
        onClickLeft() {},
    },
};
</script>

<style>
body {
    -webkit-text-size-adjust: 100% !important;
}

#root {
    width: 100%;
    height: 100%;
}
.main-box {
    padding-top: 90px;
    height:100%;
    box-sizing: border-box;
}
</style>
