<template>
    <van-nav-bar
      :title="title"
      :left-text="leftText"
      :left-arrow="leftArrow"
      :fixed="fixed"
      @click-left="clickLeft"
    >
    </van-nav-bar>
  </template>
  <script>
  export default {
    name: 'NavBar',
    props: {
      title: {
        type: String,
        default: '标题'
      },
      fixed: {
        type: Boolean,
        default: true
      },
      leftArrow: {
        type: Boolean,
        default: true
      },
      leftText: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
       
      }
    },
    watch: {
      
    },
    mounted() { },
    methods: {
      clickLeft() {
        this.$router.back();
      }
    }
  }
  </script>
  <style scoped>
     
  </style>
  