
import axios from "axios";
// import { showNotify } from 'vant';
const https = axios.create({
  timeout: 15000 // 请求超时时间
})

// respone拦截器
https.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('err' + error)// for debug
    return Promise.reject(error)
  }
)

export default https
