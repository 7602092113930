//store/modules/setting.js
 
 
//设置模块
const defaultState = {
  path: '',
  roomId:'',
  cityCode: '',
  cityName: '',
  selectCityPosition: ''
}
const getters = {
  path: defaultState => defaultState.path,
  roomId: defaultState => defaultState.roomId,
  cityCode: defaultState => defaultState.cityCode,
  cityName: defaultState => defaultState.cityName,
  selectCityPosition: defaultState => defaultState.selectCityPosition
}
const mutations={
  setPath(state, newPath){//更新性别
      state.path = newPath
  },
  setRoomId(state, newRoomId){//更新性别
    state.roomId = newRoomId
  },
  setCityCode(state, newCityCode){//更新性别
    state.cityCode = newCityCode
  },
  setCityName(state, newCityName){//更新性别
    state.cityName = newCityName
  },
  setSelectCityPosition(state, selectCityPosition){//更新性别
    state.selectCityPosition = selectCityPosition
  }
}
const actions ={}

export default {
  namespaced: true,//开启命名空间，用来映射子模块
  state: defaultState,
  mutations,
  actions,
  getters
}