
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
//导入模块
import common from './modules/common'
Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})
export default new Vuex.Store({
    strict: false,
    state: {

    },
    getters: {

    },
    mutations: {
    },
    actions: {
    },
    modules: {//挂载模块
    common
    },
    plugins: [vuexLocal.plugin]
})