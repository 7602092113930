import Vue from 'vue'
import App from '@/App'
import router from "@/router/index";
import Vant from 'vant'
import 'vant/lib/index.css'
import "@/styles/global.css"
import api from '@/api'
import store from '@/store/index';
import 'video.js/dist/video-js.css'
import { NavBar } from 'vant';
// Vue.prototype.$Map = window.TMap
Vue.use(Vant)
Vue.use(NavBar)
Vue.prototype.$yx_api = api
Vue.config.productionTip = false

new Vue({
    router,
    store,
    el:'#app',
    //  这个函数与我们写的是差不多的
   // 定义并注册了App;使用了App组件;以及比上面多了一个作用，自动寻找解析器的loader
   // 相比于上面那种写法，它的打包体积更小一点
    render:h=>h(App)
})
